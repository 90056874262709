import {
  Component,
  Input,
  OnChanges,
  OnInit,
  signal,
  SimpleChanges,
} from '@angular/core';

/** Feature Limiter Component
 *
 * This component is used to limit the usage of a feature to a certain subscription.
 * If the feature is limited, a button will be shown that allows the user to upgrade.
 * When clicked, the upgrade function will be called.
 *
 * Must be used within a relative position context. Otherwise, the component will not work.
 *
 * @example
 * <div class="relative">
 *   <fini-feature-limiter [isLimited]="true" limit="Ultimate Subscription">
 *     <p>This is an example of a feature that is limited to the Ultimate Subscription.</p>
 *   </fini-feature-limiter>
 * </div>
 */
@Component({
  selector: 'fini-feature-limiter',
  templateUrl: './feature-limiter.component.html',
  styleUrl: './feature-limiter.component.scss',
})
export class FeatureLimiterComponent implements OnChanges, OnInit {
  @Input() isLimited: boolean | string = false;
  @Input() limit: string = 'Ultimate Subscription';

  _isLimited = signal(false);

  upgrade() {
    window.alert('wohoo, lets upgrade');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isLimited']) {
      this._isLimited.set(
        typeof this.isLimited === 'boolean'
          ? this.isLimited
          : this.isLimited === 'true'
      );
    }
  }

  ngOnInit() {
    this._isLimited.set(
      typeof this.isLimited === 'boolean'
        ? this.isLimited
        : this.isLimited === 'true'
    );
  }
}
