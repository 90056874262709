<div class="feature-limited">
  @if (_isLimited()) {
  <ng-content></ng-content>
  <div class="overlay">
    <div
      class="d-flex flex-row gap-2 align-items-center justify-content-center"
    >
      <span>Sorry, this feature is limited to {{ limit }}.</span>
    </div>
    <button class="button primary small" (click)="upgrade()">
      Upgrade
      <i class="material-symbols-outlined"> upgrade </i>
    </button>
  </div>
  }
  <ng-content></ng-content>
</div>
